import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import styled from 'styled-components';

const StyledGlobalBusinessVendors = styled.div`
  text-align: center;
  padding: 5rem 0;
  h2 {
    margin-bottom: 40px;
    font-size:40px;
  }
  ul {
    text-align: center;
    list-style: none;
    display: flex;
    padding-left:5rem;
    padding-right:5rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    li {
      text-align: center;
      padding: 1rem;
      width: 50%;
      img {
        width: 180px;
      }
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    padding: 8rem 0 0;
    ul li {
      width: 25%;
    }
  }
`;

const GlobalBusinessVendors = ({ headline, logo}) => {
  return (
    <>
      <StyledGlobalBusinessVendors>
        <h2>{headline}</h2>
        <ul>
          {logo.map(item => {
            return (
              <li>
                <img src={item.source_url} alt="" />
              </li>
            );
          })}
        </ul>
      </StyledGlobalBusinessVendors>
    </>
  );
};

export default GlobalBusinessVendors;
