import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { Link, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

//import components
import NavBar from '../components/NavBar';
import ContactBanner from '../components/ContactBanner';
import RelatedContentTechnology from '../components/Technology/RelatedContentTechnology';
import DemoFormFormik from '../components/DemoFormFormik';
import Hero from '../components/Hero';

import SEO from '../components/SEO';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import GlobalOurClients from '../components/GlobalOurClients';
import GlobalBusinessVendors from '../components/GlobalBusinessVendors';
import RelatedContentTechnologyAlliance from '../components/Technology/RelatedContentTechnologyAlliance';
const StyledGlobalOurPartners = styled.div`
  text-align: center;
  margin-bottom: -5rem;
  padding: 5rem 0;
  h2 {
    margin-bottom: 40px;
  }
  ul {
    text-align: center;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    li {
      text-align: center;
      padding: 1rem;
      width: 100%;
      img {
        width: 260px;
      }
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: -8rem;
    padding: 8rem 0 0;
    ul li {
      width: 25%;
    }
  }
`;

const SingleTechnologyPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  h1 {
    font-size: 48px;
    margin-bottom: 40px;
  }
  .img-txt-2-col {
    margin: 80px 0;
  }
  p:nth-child(odd) {
    margin-bottom: 0;
  }
  .right-body {
    margin-bottom: 2rem;
    h4 {
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
  }
  .third {
    font-size: 22px;
    width: 70%;
  }
  .bullet-container a {
    color: ${colors.primaryGray};
  }
  @media (min-width: ${breakpoints.tablet}) {
    .hero-container {
      padding-left: 0;
      padding-bottom: 4.5em;
    }
    .bullet-container ul {
      width: 33%;
    }
    h1 {
      margin-bottom: 80px;
    }
    .img-txt-2-col {
      display: flex;
      margin: 100px 0;
      .image-left {
        width: 66%;
        padding-right: 8px;
      }
      .right {
        width: 33%;
      }
    }
  }
`;

class TechnologyPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false,
      isBrowser: typeof window !== 'undefined'
    };
  }
  
  optionalImageSection(postAcf) {

    if (!postAcf.image_left) {
      return '';
    } else {
      return postAcf.image_left.source_url.includes('transparent') ? ( null ) : (
        <div className="img-txt-2-col">
          <div className="image-left">
            <img src={postAcf.image_left.source_url} alt="" />
          </div>
          <div className="right">
            <h3 className="headline">{postAcf.right_headline}</h3>
            {postAcf.right_body.map(item => {
              return (
                <div className="right-body">
                  <h4>{item.sub_headline}</h4>
                  {ReactHtmlParser(item.copy)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  optionalBulletSection(postAcf) {
    if (postAcf.bullet_list_headline === '') {
      return '';
    } else {
      return (
        <div class="bullet-wrapper">
          <h2>{postAcf.bullet_list_headline}</h2>
          <div class="bullet-container">
            <ul>
              {postAcf.bullet_list_1.map(item => {
                return item.list_item_url ? (
                  <a href={item.list_item_url}>
                    <li className="copy">{item.list_item}</li>
                  </a>
                ) : (
                  <li className="copy">{item.list_item}</li>
                );
              })}
            </ul>
            <ul>
              {postAcf.bullet_list_2.map(item => {
                return item.list_item_url ? (
                  <a href={item.list_item_url}>
                    <li className="copy">{item.list_item}</li>
                  </a>
                ) : (
                  <li className="copy">{item.list_item}</li>
                );
              })}
            </ul>
            <ul>
              {postAcf.bullet_list_3.map(item => {
                return item.list_item_url ? (
                  <a href={item.list_item_url}>
                    <li className="copy">{item.list_item}</li>
                  </a>
                ) : (
                  <li className="copy">{item.list_item}</li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  }

  displayDemoForm(display) {
    if (display) {
      return <DemoFormFormik />;
    } else {
      return '';
    }
  }

  optionalGlobalPartnersSection(postAcf, postSlug) {  
    if(postSlug=='strategic-alliances'){
    if (postAcf.strategic_alliances_heading === '') {
      return '';
    }else{
        return (
          <StyledGlobalOurPartners>
            <h2>{postAcf.strategic_alliances_heading}</h2>
            <ul>
              {postAcf.strategic_alliances.map((item, i) => {
                if(item.strategic_alliances_link===''){
                return (                
                <li>
                    <img src={item.strategic_alliances_image.source_url} alt="Strategic Alliances Image Section" />
                </li>
                );
              }
              else{
                return (                 
                  <li>
                    <a href={item.strategic_alliances_link}>
                      <img src={item.strategic_alliances_image.source_url} alt="Strategic Alliances Image Section" />
                    </a>
                  </li>
                  );
              }
              })}       
            </ul>
        </StyledGlobalOurPartners>
        );
    }   
  }
  else{
    return(
    <GlobalOurClients
    headline={postAcf.client_logo_headline}
    logos={postAcf.client_logos}
  />
    );
  }
  }
  optionalPremierPartnershipSection(postAcf, postSlug) {
    if(postSlug=='strategic-alliances'){
    if (postAcf.premier_partnerships_headline === '') {
      return '';
    }else{
        return (
          <GlobalBusinessVendors
            headline={postAcf.premier_partnerships_headline}
            logo={postAcf.premier_partnerships}
          />
        );
    }
  }   
  }
  optionalBusinessVendorsSection(postAcf, postSlug) {
    if(postSlug=='strategic-alliances'){
    if (postAcf.partner_logo_headline === '') {
      return '';
    }else{
        return (
          <GlobalBusinessVendors
            headline={postAcf.business_vendors_headline}
            logo={postAcf.business_vendors}
          />
        );
    }   
  }
  }

  displayHero(post) {
    if (
      post.acf.hero_headline === null ||
      post.acf.hero_sub_headline === null
    ) {
      return <h1>{ReactHtmlParser(post.title)}</h1>;
    } else {
      return (
        <Hero
          headline={post.acf.hero_headline}
          sub={post.acf.hero_sub_headline}
        />
      );
    }
  }

  render() {
    let post = this.props.data.wordpressWpTechnologies;
    return (
      <SingleTechnologyPage className="animate">
        <SEO
          isBlogPost={false}
          postData={post}
          postImage={DefaultSharingImage}
        />

        <NavBar link="/technology" name="Technology" />
        <div className="page-container">
          {this.displayHero(post)}
          <p className="intro-copy">{ReactHtmlParser(post.acf.intro_copy)}</p>
          {this.optionalImageSection(post.acf)}
          {this.displayDemoForm(post.acf.display_demo_form)}
          {this.optionalBulletSection(post.acf)}
          {this.optionalGlobalPartnersSection(post.acf, post.slug)}

          {this.state.isBrowser ? 
            window.location.href.indexOf("strategic-alliances") > -1 ?
              null
            :
              <>
              {this.optionalPremierPartnershipSection(post.acf, post.slug)}
              {this.optionalBusinessVendorsSection(post.acf, post.slug)}
              </>
            
          :
            null
          }

          {this.state.isBrowser ?
            <>
            <div style={{height: '130px'}}></div> {/* temp shim */}
            {window.location.href.indexOf("strategic-alliances") > -1 ? <RelatedContentTechnologyAlliance /> : <RelatedContentTechnology />}
            </>
            :
            null
          }
          
          <ContactBanner
            headline={post.acf.contact_headline}
            cta={post.acf.contact_cta}
            url={post.acf.contact_url}
          />
        </div>
      </SingleTechnologyPage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpTechnologies(slug: { eq: $slug }) {
      title
      type
      content
      slug
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        bullet_list_1 {
          list_item
          list_item_url
        }
        bullet_list_2 {
          list_item
          list_item_url
        }
        bullet_list_3 {
          list_item
          list_item_url
        }
        strategic_alliances_heading
        strategic_alliances {
        strategic_alliances_link
        strategic_alliances_image {
          source_url
        }
      }
        premier_partnerships_headline
        premier_partnerships{
          source_url
        }
        business_vendors_headline
        business_vendors{
            source_url
        }
        display_demo_form
        bullet_list_headline
        client_logo_headline
        client_logos {
          source_url
        }
        contact_headline
        contact_cta
        contact_url
        image_left {
          source_url
        }
        right_headline
        right_body {
          sub_headline
          copy
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default TechnologyPageTemplate;
